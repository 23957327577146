import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
//            ...GatsbyImageSharpFluid
/*
 Use these when you make GraphQL query and
 can't use a fragment like ...GatsbyImageSharpFluid
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
 */
/*
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }

 */

const ImageSiteLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      imageLogoHorizontal: file(relativePath: { eq: "3-Leaps-Horizontal-Color-72ppi.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.imageLogoHorizontal.childImageSharp.fluid}
      alt="3 Leaps Logo"
      imgStyle={{ objectFit: "contain", objectPosition: "left" }}
    />
  )
}

export default ImageSiteLogo