import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import NonStretchedImage from "../nonStretchedImage"
import fragmentFluidImageMax1000 from "../fragmentFluidImage"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
//            ...GatsbyImageSharpFluid
/*
 Use these when you make GraphQL query and
 can't use a fragment like ...GatsbyImageSharpFluid
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
 */
/*
 Put this in to fix aspect ratio and size so doesn't grow, but doesn't shrink either
     imgStyle={{width: 'auto', height: 'auto'}}
 */
// putting presentationWidth in the query causes image to disappear - need to know how to use this
/*
 *  Important to make sure aspectRatio is in fluid query - it will be with
 * the GatsbyImageSharpFluid
 *
 *  Notice we set objectFit to contain so the entire image fits in the container
 *
 *  Using objectFit contain will make object shrink to nothing if container shrinks
 *
 */
/*
 * objectFit values:
 *    fill
 *    contain (like fill but preserves aspect ratio)
 *    cover
 *    none
 *    scale-down
 */
const ImageSiteIcon = () => {
  const data = useStaticQuery(graphql`
    query {
      iconImage: file(relativePath: { eq: "3-Leaps-Symbol-Color-72ppi.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)
  return <Img
    fluid={data.iconImage.childImageSharp.fluid}
    src={data.iconImage.childImageSharp.src}
    alt="3 Leaps Icon"
    imgStyle={{ objectFit: 'contain', objectPosition: 'left'}}
  />
}

/*
    style={{
      width: {data.iconImage.childImageSharp.sizes.presentationWidth},
      maxWidth: {data.iconImage.childImageSharp.sizes.presentationWidth},
      display: "inline-block"
    }}

 */
export default ImageSiteIcon