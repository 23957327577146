import React from "react"
import { Anchor, Box, Footer as GrommetFooter, Text } from "grommet"

import {
  Instagram,
  Linkedin, Twitter, MailOption, Mail, Chat } from "grommet-icons"

import Link from "../components/link"
import ImageSiteIcon from "./imageHandling/imageSiteIcon"

/*
 *  DET FIXME - add chat / text option on separate page later
 *    Use <Chat> icon
 */
const Contact = () => (
  <Box
    as={"nav"}
    flex={false}
    direction="row"
    gap={"xxsmall"}
    alignContent={"center"}
    alignSelf={"center"}
    hoverIndicator={{dark: "true"}}
  >
    <Anchor
      a11yTitle="Contact by email"
      href="/contact-by-message/"
      icon={<Mail/>}
    />
  </Box>
)

const Media = () => (
  <Box
    as={"nav"}
    direction="row-responsive"
    gap={"xxsmall"}
    alignContent={"center"}
    alignSelf={"center"}
  >
    <Anchor
      a11yTitle="Follow us on LinkedIn"
      href="https://www.linkedin.com/company/3leapsllc"
      icon={<Linkedin/>}
      hoverIndicator
    />
    <Anchor
      a11yTitle="Follow us on Twitter"
      href="https://twitter.com/3Leaps"
      icon={<Twitter/>}
      hoverIndicator
    />
    <Anchor
      a11yTitle="Follow us on Instagram"
      href="https://www.instagram.com/threeleaps/"
      icon={<Instagram/>}
      hoverIndicator
    />
  </Box>
)

        /*
            <Text textAlign="left" size="xsmall">
      Built with
      {` `}
      <Link href="https://www.gatsbyjs.org">Gatsby</Link>
      {` and `}
      <Link href="https://v2.grommet.io">Grommet</Link>
    </Text>

         */
/*
 Footer notes:
 1) on GrommetFooter parent, dropped the pad
    pad={{horizontal: "large", vertical: "xsmall"}}
 2) On left icon and copyright
  a) use alignSelf="center" to make the text center vertically with icon
 */
const Footer = ({ copyrightOwner }) => (
  <GrommetFooter
    background="light-2"
    pad={{
      "left": "medium",
      "right": "medium"
    }}
    flex={{shrink: 0}}
  >
    <Box
      basis={"small"}
      border={false}
      justify={"start"}
      direction={"row-responsive"}
      wrap={true}
      gap={"small"}>
      <Box
        basis={"xxsmall"}
        border={false}
        height={{ max: "40" }}
        align={"stretch"}
        alignContent={"start"}
        pad="xsmall"
        gap={"xsmall"}>
        <ImageSiteIcon/>
      </Box>
      <Box direction={"row-responsive"}>
        <Text alignSelf="start" size="small" textAlign={"start"}>
          © {new Date().getFullYear()} &nbsp;
        </Text>
        <Text alignSelf="center" size="small" textAlign={"start"}>
          {copyrightOwner}.
        </Text>
      </Box>
    </Box>
    <Box
      basis={"auto"}
      border={false}
      alignContent={"center"}
      alignSelf={"center"}
      direction={"row-responsive"}
      gap={"xsmall"}>
      <Text
        weight={"bold"}
        alignSelf={"center"}
        textAlign={"center"}
      >
        FOLLOW US
      </Text>
      <Media/>
    </Box>
    <Box
      basis={"auto"}
      border={false}
      alignContent={"center"}
      alignSelf={"center"}
      direction={"row-responsive"}
      gap={"xsmall"}
    >
      <Text
        weight={"bold"}
        alignSelf={"center"}
        textAlign={"center"}
      >
        CONTACT US
      </Text>
      <Contact />
    </Box>
  </GrommetFooter>
)

export default Footer