import { deepFreeze } from "grommet/utils"

/*
        "neutral-1": "#20366A",
        "neutral-2": "#D9C098",
        "neutral-3": "#80746E",
        "neutral-4": "#3A6BA5",

        "brand": "#acc3a6",
        "focus": "#5fa7bf",
        "neutral-1": "#9fbcc0",
        "neutral-2": "#7ea5a8",
        "neutral-3": "#80746E",
        "neutral-4": "#3A6BA5",

 */
export const siteThemeAlpha = deepFreeze(
  {
    "global": {
      "colors": {
        "brand": "#078578",
        "light-2": "#f4f7fa",
        "focus": "#7faf82",
        "accent-1": "#00009C",
        "accent-2": "#0fb7dc",
        "accent-3": "#FF964C",
        "accent-4": "#B00000",
        "neutral-1": "#20366A",
        "neutral-2": "#6b1153",
//        "neutral-3": "#4e6680",
        "neutral-3": "#364861",
        "neutral-4": "#3A6BA5",
        "status-critical": "#F04953",
        "status-error": "#F04953",
        "status-warning": "#FFD144",
        "status-ok": "#01a982"
      },
      "font": {
        "family": "ManropeRegular",
      }
    },
    "anchor": {
      "textDecoration": "none",
      "hover": {
        "textDecoration": "underline #078578",
      },
      "color": {
        "dark": "#FFFFFF",
        "light": "#000000"
      }
    },
    "button": {
      "border": {
        "radius": "0px"
      }
    },
    "clock": {
      "analog": {
        "second": {
          "color": {
            "dark": "#01a982",
            "light": "#01a982"
          }
        }
      }
    }
  }
)