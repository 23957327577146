import PropTypes from "prop-types"
import React from "react"
import { Anchor, Box, Grid, Header as GrommetHeader, Heading, Text } from "grommet"
import Link from "../components/link"
import SiteMenu from "./siteMenu"
import ImageSiteLogo from "./imageHandling/imageSiteLogo"
import styled from 'styled-components'
import { navigate } from "gatsby"

const HeaderMenuText = ({ ...rest }) => (
  <Text
    weight={"bold"}
    color={"black"}
    align={"center"}
    {...rest}
  />
)

const CustomFontText = styled(Text)`
font-family: ManropeExtraBold`;

/*
 Format Notes
 1) Parent box container
  a) use justify=between on the parent box to spread things out
  b) use alignSelf=between for same
  b) use basis=small or xsmall to send panding
  c) use margin bottom as xsmall or small,
      and top as none, don't use pad

 The header shows stacked on mobile because of the width of elements - you can
 see this by shrinking horizontal on desktop.  You can change direction
 to row instead of row-responsive, but really problem is the fixed size
 logo so change that next
 */
/*
  Add back if you put a master box in the header
     <Box
      margin={{
        "bottom": "xsmall",
        "top": "none"
      }}
      basis={"full"}
      height={"auto"}
      border={false}
      justify={"between"}
      alignContent={"end"}
      alignSelf={"between"}
      direction={"row"}
      wrap={true}
      gap={"small"}
    >

 */
// DET FIXME - parameterize the link children here

/*
 *  Temporarily removed the how we can help since haven't parameterized site menu yet
<Link to={"/how-we-can-help"}>
  <HeaderMenuText>
    How We Can Help
  </HeaderMenuText>
</Link>
* There was a        <SiteMenu />
 at bottom
 */

const Header = ({ siteTitle, headerTagline }) => (
  <GrommetHeader
    background="light-2"
    justify={"between"}
    basis={"auto"}
    border={false}
    justify={"between"}
    alignContent={"start"}
    direction={"row"}
    wrap={true}
    gap={"small"}
    pad={{
      "left": "medium",
      "right": "medium",
      "bottom": "small"
    }}
    margin={{
      "bottom": "none",
      "top": "none",
    }}
  >
        <Box
          basis={"small"}
          border={false}
          direction={"column"}
          gap={"xsmall"}
          margin={{
            "top": "xxsmall",
            "bottom": "xxsmall"
          }}
          alignSelf={"center"}
        >
          <Link
            to={"/"}
            a11yTitle={"3 Leaps Site Logo"}
          >
            <ImageSiteLogo/>
          </Link>
        </Box>
        <Box
          alignContent={"center"}
          alignSelf={"center"}
          gap={"medium"}
          justify={"center"}
          direction={"row-responsive"}
        >
          <Link to={"/who-we-are/"}>
            <HeaderMenuText>Who We Are</HeaderMenuText>
          </Link>
          <Link to={"/what-we-do/"} >
            <HeaderMenuText>
              What We Do
            </HeaderMenuText>
          </Link>
          <Link to={"/blog/"}>
            <HeaderMenuText>Blog</HeaderMenuText>
          </Link>
          <Link to={"/contact-by-message/"}>
            <HeaderMenuText>Contact Us</HeaderMenuText>
          </Link>
        </Box>
  </GrommetHeader>
)


Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
