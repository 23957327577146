import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            siteUrl
            headline
            description
            mission
            twitter
            name
          }
        }
      }
    `
  )
  // return the whole structure and let caller get what is needed
  return site
}
