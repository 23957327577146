/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Grommet, Box } from "grommet"
//import { dark } from "grommet/themes"
import "./fonts.css"
import {siteThemeAlpha} from "./siteThemeAlpha"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import Header from "./header"
import Footer from "./footer"

/*
 * CSS NOTES
 *
 * 1) Used flex shrink 0 in footer AND 1 0 auto in main box to provide a sticky footer
 */
const Layout = ({ children }) => {
  const { siteMetadata }  = useSiteMetadata()

  return (
    <Grommet
      theme={siteThemeAlpha}
      full
      plain={false}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header siteTitle={siteMetadata.title} />
      <Box
        flex={{grow: 1}}
        overflow="auto"
        height={{
          "min": "800"
        }}
        width={{
          "min": "460"
        }}
      >
        {children}
      </Box>
      <Footer copyrightOwner={siteMetadata.name}/>
    </Grommet>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
